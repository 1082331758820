import { toast } from 'react-toastify';
import { Button, MpesaWrapper } from './Mpesa.styles';

import axios from 'axios';

const Mpesa = ({
   allBookingInformation,
   setIsMpesaLoading,
   isMpesaLoading,
   setMpesaPopupResult,
   // mpesaPopupResult,
}) => {
   const sendPopupToUser = async () => {
      setIsMpesaLoading(true);
      try {
         const res = await axios.post(
            `${process.env.REACT_APP_API_MODULE_DOMAIN}/paymethods/mpesa/mpesa_pay`,
            {
               phone: allBookingInformation?.passengerInformation?.login_mobile,
               // phone: '254708374149',
               amount: allBookingInformation?.returnAmmount,
               // amount: 1,
            },
            {
               headers: {
                  'Content-Type': 'application/json',
               },
            }
         );
         const response = res.data;

         if (response?.status === 'Success') {
            setMpesaPopupResult(response);
         } else if (response?.status === 'failed') {
            setIsMpesaLoading(false);
            toast.error(response?.message);
         } else {
            setIsMpesaLoading(false);
            toast.error('Something went wrong on Mpesa payment!');
         }
      } catch (err) {
         setIsMpesaLoading(false);
         toast.error('Something went wrong on requesting Mpesa payment!');
         console.log(err);
      }
   };

   return (
      <MpesaWrapper>
         {!isMpesaLoading && (
            <Button onClick={sendPopupToUser}>
               <img
                  src="/img/mpesa.png"
                  alt="mpesa logo"
                  style={{ width: '150px' }}
               />
            </Button>
         )}
         {isMpesaLoading && (
            <h2 style={{ margin: '0', padding: '0' }}>Loading...</h2>
         )}
      </MpesaWrapper>
   );
};

export default Mpesa;
