import styled from 'styled-components';

export const Summary = styled.div`
   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
   padding: 10px;
   text-transform: capitalize;
`;
export const FareSummaryWrapper = styled.div`
   text-align: left;
   border-bottom: 2px solid #eaeaea;
   padding-bottom: 20px;
   padding-top: 10px;
   font-size: 14px;
`;
export const FareSummaryHeader = styled.h4`
   font-size: 16px;
   margin: 0px;
   padding: 0px;
`;
export const Amount = styled.div`
   border-bottom: 2px solid #eaeaea;
   padding-bottom: 20px;
   padding-top: 10px;
   font-size: 14px;
`;
export const BaseFare = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
`;
export const TaxBottom = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
`;
export const DepartureAmmount = styled.div`
   border-bottom: 2px solid #eaeaea;
   padding-bottom: 10px;
   margin-bottom: 10px;
`;
export const Tax = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 10px;
`;
export const SubTotalAmount = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 2px solid #eaeaea;
`;
export const Discount = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-top: 10px;
   margin-bottom: 10px;
`;
export const TotalAmount = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-top: 10px;
`;
