import styled from 'styled-components';

export const Wrapper = styled.div``;
export const JourneyDetails = styled.div`
   display: flex;
   flex-wrap: wrap;
   padding: 20px 0px;
`;
export const PickLocation = styled.div`
   flex: 1;
`;
export const JourneyDetailsProperty = styled.span`
   text-transform: capitalize;
`;
export const DropLocation = styled.div`
   flex: 1;
   text-align: right;
`;
export const Time = styled.div`
   display: flex;
   flex-wrap: wrap;
   padding-bottom: 20px;
   border-bottom: 1px solid #d9d9d9;
`;
export const BookingTime = styled.div`
   flex: 1;
`;
export const TimeProperty = styled.span`
   text-transform: capitalize;
`;
export const JourneyTime = styled.div`
   flex: 1;
   text-align: right;
`;
export const SeatAndAmount = styled.div`
   display: flex;
   flex-wrap: wrap;
   padding: 20px 0px;
   border-bottom: 1px solid #d9d9d9;
`;
export const Seat = styled.div`
   flex: 1;
`;
export const SeatProperty = styled.div`
   text-transform: capitalize;
`;
export const SeatNumber = styled.div``;
export const AmountWrapper = styled.div`
   flex: 1;
   text-align: right;
`;
export const AmountProperty = styled.div`
   text-transform: capitalize;
`;
export const Amount = styled.div``;
export const Discount = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   // padding-top: 20px;
   text-transform: capitalize;
`;
export const Luggage = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   text-transform: capitalize;
`;
export const TotalTax = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   text-transform: capitalize;
`;
export const Total = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   text-transform: capitalize;
`;
export const Due = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   padding-bottom: 20px;
   text-transform: capitalize;
`;
