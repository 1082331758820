import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import fetchSeatData from '../../../helpers/fetch-seat-data';
// import languageData from "../../../lib/lang.config.json";
import Flutterwave from '../PaymentGateway/Flutterwave';
import NewPayPal from '../PaymentGateway/NewPayPal';
import Paystack from '../PaymentGateway/Paystack';
import Razorpay from '../PaymentGateway/Razorpay';
import SslCommerz from '../PaymentGateway/SslCommerz';
import StripeCheckoutBtn from '../PaymentGateway/StripeCheckoutBtn';
import {
   ButtonWrapper,
   CancelButton,
   Label,
   PaymentAndButton,
   PaymentButton,
   PaymentInput,
   PaymentMethod,
   PaymentUl,
   SinglePayment,
} from './Payment.styles';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import Mpesa from '../PaymentGateway/Mpesa';
import axios from 'axios';
import { PaymentHelper } from '../PaymentGateway/PaymentHelper';
import { ticketTracking } from '../../../redux/action/busAction';

const Payment = ({
   handleSubmitPayment,
   paymentStutas,
   setPaymentStutas,
   paymentGateway,
   setpaymentGateway,
   passengerInformation,
   allBookingInformation,
   setLoading,
}) => {
   const history = useHistory();
   const dispatch = useDispatch();
   const { webSettingData } = useSelector((state) => state.busLists);
   const languageData = useSelector((state) => state.busLists.languageData);
   const [paymentMethods, setPaymentMethods] = useState(null);
   const [seatsForBooking, setSeatsForBooking] = useState([]);
   const [date, setDate] = useState();
   const [payPalClientId, setPayPalPaymentId] = useState(null);
   const [isMpesaLoading, setIsMpesaLoading] = useState(false);
   const [mpesaPopupResult, setMpesaPopupResult] = useState(null);
   const [mpesaCountdown, setMpesaCountdown] = useState(false);
   const [seconds, setSeconds] = useState(0);

   const getPaymentMethods = async () => {
      const response = await fetch(
         `${process.env.REACT_APP_API_MODULE_DOMAIN}/paymethods`
      );

      const result = await response.json();

      if (result?.status === 'success') {
         setPaymentMethods(result?.data);
      }
   };

   const getPaypalData = async () => {
      const response = await fetch(
         `${process.env.REACT_APP_API_MODULE_DOMAIN}/paymethods/paypal`
      );
      const result = await response.json();

      if (result?.status === 'success') {
         setPayPalPaymentId(result?.data?.client_id);
      }
   };

   useEffect(() => {
      try {
         getPaymentMethods();
      } catch (error) {
         console.error('paymentMethods', error);
      }
   }, []);

   useEffect(() => {
      getPaypalData();
   }, []);

   const handleCancel = () => {
      history.push('/');

      localStorage.removeItem('bookingInfo');
      localStorage.removeItem('searchInfo');
      localStorage.removeItem('returnFirstJourneyInfo');
      localStorage.removeItem('journeyInfo');
      localStorage.removeItem('discount');
      localStorage.removeItem('regular');
      localStorage.removeItem('return');
      localStorage.removeItem('bookingInfoTax');
      localStorage.removeItem('journeyInfoTax');
      localStorage.removeItem('subtripId');
   };

   useEffect(() => {
      const searchInfo = JSON.parse(localStorage.getItem('searchInfo'));
      setDate(searchInfo?.journeydate);

      if (allBookingInformation?.bookingInfo?.seatnumbers) {
         const _convertToArray =
            allBookingInformation?.bookingInfo?.seatnumbers?.split(',');
         setSeatsForBooking(
            _convertToArray.length
               ? _convertToArray
               : [allBookingInformation?.bookingInfo?.seatnumbers]
         );
      }
   }, []);

   const paymentStutasChange = async (e) => {
      if (date && allBookingInformation?.bookingInfo?.subtripId) {
         const result = await fetchSeatData(
            date,
            allBookingInformation?.bookingInfo?.subtripId
         );

         // Check response data
         if (!result?.seatlayout) {
            toast.error('Something went wrong in data loading!');
         } else {
            // Set in state
            const _seatInfo = result?.seatlayout;

            const isSeatAvailable = [];
            seatsForBooking.forEach((seat) => {
               _seatInfo.forEach((seats) => {
                  seats.forEach((item) => {
                     if (!item) return;
                     if (seat === item?.seat_no && item.isReserved) {
                        isSeatAvailable.push(0);
                     } else {
                        isSeatAvailable.push(1);
                     }
                  });
               });
            });
            if (isSeatAvailable.includes(0)) {
               toast.error('Your selected seat booked! please try another...');
               return;
            }
         }
      }

      // optional
      // if (!passengerInformation?.login_email) {
      //    toast('Fill in the contact email');
      //    return;
      // }
      if (!passengerInformation?.login_mobile) {
         toast('Phone number is required');
         return;
      } else if (!passengerInformation?.first_name) {
         toast('First name is required');
         return;
      } else if (!passengerInformation?.last_name) {
         toast('Last name is required');
         return;
      } else if (!passengerInformation?.id_number) {
         toast('Document number is required');
         return;
      }

      // else if (!passengerInformation?.zip_code) {
      //   toast("set all information");
      //   return;
      // } else if (!passengerInformation?.city) {
      //   toast("set all information");
      //   return;
      // } else if (!passengerInformation?.address) {
      //   toast("set all information");
      //   return;
      // } else if (!passengerInformation?.country_id) {
      //   toast("set all information");
      //   return;
      // }

      setPaymentStutas(e.target.value);
      setpaymentGateway('');
   };

   const validateEmail = (email) => {
      return String(email)
         .toLowerCase()
         .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         );
   };

   useEffect(() => {
      var ele = document.querySelectorAll("input[type='radio']:checked");
      for (let index = 0; index < ele.length; index++) {
         ele[index].checked = false;
      }
      setPaymentStutas('');
   }, [passengerInformation, setPaymentStutas]);

   const initPayPal = {
      'client-id': payPalClientId,
      currency: 'USD',
      intent: 'capture',
   };

   const handleTicketTracking = async (id) => {
      setLoading(false);
      const response = await fetch(
         `${process.env.REACT_APP_API_MODULE_DOMAIN}/tickets/bookingid/${id}`
      );
      const result = await response.json();

      if (result?.status === 'success') {
         dispatch(ticketTracking(result?.data));
         history.push('/ticket-traking');
         toast.success('success');
      }
   };

   useEffect(() => {
      if (
         mpesaPopupResult?.transection_id &&
         mpesaPopupResult?.checkout_request_id
      ) {
         alert(mpesaPopupResult?.message);
         setMpesaCountdown(true);
         setSeconds(30);
         setTimeout(async () => {
            try {
               const res = await axios.post(
                  `${process.env.REACT_APP_API_MODULE_DOMAIN}/paymethods/mpesa/mpesa-validate`,
                  {
                     transection_id: mpesaPopupResult.transection_id,
                     checkout_request_id: mpesaPopupResult.checkout_request_id,
                  },
                  {
                     headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                     },
                  }
               );

               const response = res.data;

               setMpesaCountdown(false);
               setIsMpesaLoading(false);
               setMpesaPopupResult(null);

               if (response.status === 'Success') {
                  setLoading(true);
                  PaymentHelper(
                     {
                        ...allBookingInformation,
                        paymentGateway: '7',
                        paymentStutas: 'Pay Now',
                     },
                     dispatch
                  ).then((res) => {
                     handleTicketTracking(res?.data?.booking_id);
                  });
               } else if (response.status === 'failed') {
                  // setLoading(true);
                  // PaymentHelper(
                  //    {
                  //       ...allBookingInformation,
                  //       paymentGateway: '7',
                  //       paymentStutas: 'Pay Now',
                  //    },
                  //    dispatch
                  // ).then((res) => {
                  //    handleTicketTracking(res?.data?.booking_id);
                  // });
                  toast.error(response.message);
               } else {
                  toast.error('Something went wrong on Mpesa validation!');
               }
            } catch (err) {
               setMpesaCountdown(false);
               setIsMpesaLoading(false);
               setMpesaPopupResult(null);
               toast.error('Something went wrong on Mpesa payment!');
            }
         }, 30000);
      }
   }, [mpesaPopupResult]);

   // 30 second countdown
   useEffect(() => {
      if (seconds > 0) {
         const countdown = setInterval(() => {
            if (seconds > 0) {
               setSeconds((prevSeconds) => prevSeconds - 1);
            }
         }, 1000);

         return () => clearInterval(countdown);
      }
   }, [seconds]);

   return (
      <>
         {mpesaCountdown && (
            <div style={{ marginTop: '20px', color: '#f94449' }}>
               <h2 style={{ margin: '0', padding: '0' }}>
                  Please wait for {seconds} seconds, and don't try to refresh
                  this page!
               </h2>
               <h3 style={{ margin: '0', padding: '0' }}>
                  Need to validate this payment, Stay with us.
               </h3>
               <h4 style={{ margin: '0', padding: '0' }}>
                  Timeout is completing transaction...
               </h4>
            </div>
         )}

         <PaymentMethod>
            <SinglePayment>
               <PaymentInput
                  type="radio"
                  id="payNow"
                  name="paymentStutas"
                  value="Pay Now"
                  onChange={(e) => paymentStutasChange(e)}
               />
               <Label htmlFor="payNow">
                  {
                     languageData?.checkout_page_payNow_radio_btn[
                        webSettingData?.language
                     ]
                  }
               </Label>
            </SinglePayment>

            {webSettingData?.pay_later && (
               <SinglePayment>
                  <PaymentInput
                     type="radio"
                     id="payLatter"
                     name="paymentStutas"
                     value="Pay Latter"
                     onChange={(e) => paymentStutasChange(e)}
                  />
                  <Label htmlFor="payLatter">
                     {
                        languageData?.checkout_page_payLatter_radio_btn[
                           webSettingData?.language
                        ]
                     }
                  </Label>
               </SinglePayment>
            )}
         </PaymentMethod>

         <PaymentAndButton>
            {paymentStutas === 'Pay Now' && (
               <PaymentUl>
                  {paymentMethods?.map((item) => (
                     <SinglePayment key={item.id}>
                        <PaymentInput
                           type="radio"
                           id={item.name}
                           name="payment"
                           value={item.name}
                           onChange={(e) => setpaymentGateway(e.target.value)}
                        />
                        <Label htmlFor={item.name}>
                           {languageData?.[item.name][webSettingData?.language]}
                        </Label>
                     </SinglePayment>
                  ))}
               </PaymentUl>
            )}

            {paymentStutas === 'Pay Now' && (
               <>
                  {(() => {
                     // enable the line if the email is required in checkout page
                     // if (validateEmail(passengerInformation.login_email)) {
                     if (passengerInformation.login_mobile) {
                        const emailError =
                           document.getElementById('email-error');
                        if (emailError) {
                           emailError.innerHTML = '';
                        }
                        return (
                           <ButtonWrapper>
                              <CancelButton onClick={handleCancel}>
                                 {
                                    languageData?.checkout_page_cancel_btn[
                                       webSettingData?.language
                                    ]
                                 }
                              </CancelButton>
                              {paymentGateway ? (
                                 <>
                                    {paymentGateway === 'paypal' &&
                                       payPalClientId && (
                                          <PayPalScriptProvider
                                             options={initPayPal}
                                          >
                                             <PayPalButtons
                                                fundingSource="paypal"
                                                style={{ layout: 'vertical' }}
                                                disabled={false}
                                             >
                                                <NewPayPal
                                                   passengerInformation={
                                                      passengerInformation
                                                   }
                                                   allBookingInformation={
                                                      allBookingInformation
                                                   }
                                                   setLoading={setLoading}
                                                />
                                             </PayPalButtons>
                                          </PayPalScriptProvider>
                                       )}

                                    {paymentGateway === 'paystack' && (
                                       <Paystack
                                          passengerInformation={
                                             passengerInformation
                                          }
                                          allBookingInformation={
                                             allBookingInformation
                                          }
                                          setLoading={setLoading}
                                       />
                                    )}

                                    {paymentGateway === 'stripe' && (
                                       <StripeCheckoutBtn
                                          allBookingInformation={
                                             allBookingInformation
                                          }
                                          setLoading={setLoading}
                                       />
                                    )}

                                    {paymentGateway === 'razorpay' && (
                                       <Razorpay
                                          allBookingInformation={
                                             allBookingInformation
                                          }
                                          setLoading={setLoading}
                                       />
                                    )}

                                    {paymentGateway === 'flutterwave' && (
                                       <Flutterwave
                                          allBookingInformation={
                                             allBookingInformation
                                          }
                                          setLoading={setLoading}
                                       />
                                    )}

                                    {paymentGateway === 'sslcommerz' && (
                                       <SslCommerz
                                          allBookingInformation={
                                             allBookingInformation
                                          }
                                          setLoading={setLoading}
                                       />
                                    )}

                                    {paymentGateway === 'mpesa' && (
                                       <Mpesa
                                          allBookingInformation={
                                             allBookingInformation
                                          }
                                          setLoading={setLoading}
                                          setIsMpesaLoading={setIsMpesaLoading}
                                          isMpesaLoading={isMpesaLoading}
                                          mpesaPopupResult={mpesaPopupResult}
                                          setMpesaPopupResult={
                                             setMpesaPopupResult
                                          }
                                       />
                                    )}
                                 </>
                              ) : (
                                 <PaymentButton
                                    onClick={() =>
                                       handleSubmitPayment(paymentStutas)
                                    }
                                    btnbgcolor={webSettingData?.buttoncolor}
                                    btnbghvcolor={
                                       webSettingData?.buttoncolorhover
                                    }
                                    btntextcolor={
                                       webSettingData?.buttontextcolor
                                    }
                                 >
                                    {
                                       languageData
                                          ?.checkout_page_make_payment_btn[
                                          webSettingData?.language
                                       ]
                                    }
                                 </PaymentButton>
                              )}
                           </ButtonWrapper>
                        );
                     } else if (passengerInformation?.login_email) {
                        document.getElementById('email-error').innerHTML =
                           'email is not valid';
                     }
                  })()}
               </>
            )}
         </PaymentAndButton>

         {paymentStutas === 'Pay Latter' && (
            <ButtonWrapper>
               <CancelButton onClick={handleCancel}>
                  {
                     languageData?.checkout_page_cancel_btn[
                        webSettingData?.language
                     ]
                  }
               </CancelButton>
               <PaymentButton
                  onClick={() => handleSubmitPayment(paymentStutas)}
                  btnbghvcolor={webSettingData?.buttoncolorhover}
                  btnbgcolor={webSettingData?.buttoncolor}
               >
                  {
                     languageData?.checkout_page_book_your_ticket_btn[
                        webSettingData?.language
                     ]
                  }
               </PaymentButton>
            </ButtonWrapper>
         )}
      </>
   );
};

export default Payment;
