import React from "react";
import { Wrapper } from "./Laggues.styles.js";

const Luggage = () => {
  return (
    <Wrapper>
      <h4>Comming soon</h4>
    </Wrapper>
  );
};

export default Luggage;
