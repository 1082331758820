import styled from "styled-components";

export const SslCommerzWrapper = styled.div``;
export const Button = styled.button`
  cursor: pointer;
  padding: 1px;
  border-radius: 5px;
  background: #f0f8ff00;
  color: white;
  border: none;
  display: flex;
  align-items: center;
`;