import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
   Adult,
   Children,
   FreeLuggage,
   InputArea,
   InputHeader,
   PaidLuggage,
   PriceField,
   SingleInput,
   Special,
   SpecialLuggageWrap,
} from './SeatCount.styles.js';
import { CommonNavItem } from '../BoardingAndDropping/BoardingAndDropping.styles.js';
import { Input } from '../TrackOrder/TrackOrder.styles.js';
import { CheckBoxUl, CommonLabel } from './../SideBar/SideBar.styles.js';

const SeatCount = ({
   totalSelectSeat,
   setTotalSelectSeat,
   setAdultSelectSeat,
   adultSelectSeat,
   setSpecialSelectSeat,
   childrenSelectSeat,
   setChildrenSelectSeat,
   specialSelectSeat,
   luggageSettings,
   setLuggageData,
   setNewLuggageData,
   newLuggageData,
   setSpecialLuggageName,
   specialLuggageName,
}) => {
   const { webSettingData, languageData } = useSelector(
      (state) => state.busLists
   );
   const [showSpecialLuggage, setShowSpecialLuggage] = useState(false);

   const [bookingData, setBookingData] = useState(null);
   // const [languageData, setLanguageData] = useState();

   useEffect(() => {
      const bookingInfo = JSON.parse(localStorage.getItem('bookingInfo'));
      setBookingData(bookingInfo);
   }, []);
   // useEffect(() => {
   //   fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/localize/strings`)
   //     .then((res) => res.json())
   //     .then((data) => setLanguageData(data.data));
   // }, []);

   const handleChildren = (e) => {
      if (e.target.value < 0) return;

      let totalSet = totalSelectSeat - Number(specialSelectSeat);

      if (e.target.value > Number(totalSet)) {
         toast.error(`You can't take more than ${totalSet}`);
      } else {
         if (e.target.value > Number(bookingData?.cseat)) {
            toast.error(
               `${bookingData?.cseat} seat(s) are available only for children.`
            );
            setChildrenSelectSeat(e.target.value - 1);
            return;
         }
         setChildrenSelectSeat(e.target.value);

         setAdultSelectSeat(
            Number(specialSelectSeat) + totalSelectSeat - e.target.value
         );
      }
   };

   const handleAdult = (e) => {
      if (e.target.value < 0) return;

      if (e.target.value > Number(totalSelectSeat)) {
         toast.error(`You only selected ${totalSelectSeat} seat(s).`);

         setAdultSelectSeat(Number(totalSelectSeat));
      } else {
         setAdultSelectSeat(e.target.value);
      }
   };

   const handleSpecial = (e) => {
      if (e.target.value < 0) return;

      let totalSet = totalSelectSeat - Number(childrenSelectSeat);

      if (e.target.value > Number(totalSet)) {
         // setAdultSelectSeat(e.target.value - totalSelectSeat);
         toast.error(`You can't take more than ${totalSet}`);
      } else {
         if (e.target.value > Number(bookingData?.spseat)) {
            toast.error(
               `${bookingData?.spseat} seat(s) are available only for special.`
            );
            setSpecialSelectSeat(e.target.value - 1);
            return;
         }
         setSpecialSelectSeat(e.target.value);
         setAdultSelectSeat(
            totalSelectSeat - Number(childrenSelectSeat) - e.target.value
         );
      }
   };

   const handleLuggage = (e, canTakeTotal) => {
      const totalSeat = totalSelectSeat - Number(childrenSelectSeat);
      const value = Number(e.target.value) || '';
      const limit = totalSeat * canTakeTotal;
      const target = e.target.id;

      switch (target) {
         case 'free_luggage_pcs':
            if (value > limit) {
               toast.error(`You can't take more than ${limit}`);
               setNewLuggageData((prev) => ({
                  ...prev,
                  free_luggage_pcs: limit,
               }));
               return;
            } else if (value < 0) {
               setNewLuggageData((prev) => ({
                  ...prev,
                  free_luggage_pcs: '',
               }));
            } else {
               setNewLuggageData((prev) => ({
                  ...prev,
                  free_luggage_pcs: value,
               }));
            }
            break;

         case 'paid_max_luggage_pcs':
            if (value > limit) {
               toast.error(`You can't take more than ${limit}`);
               setNewLuggageData((prev) => ({
                  ...prev,
                  paid_max_luggage_pcs: limit,
               }));
               return;
            } else if (value < 0) {
               setNewLuggageData((prev) => ({
                  ...prev,
                  paid_max_luggage_pcs: '',
               }));
            } else {
               setNewLuggageData((prev) => ({
                  ...prev,
                  paid_max_luggage_pcs: value,
               }));
            }
            break;

         case 'free_luggage_kg':
            if (value > limit) {
               toast.error(`You can't take more than ${limit}`);
               setNewLuggageData((prev) => ({
                  ...prev,
                  free_luggage_kg: limit,
               }));
               return;
            } else if (value < 0) {
               setNewLuggageData((prev) => ({
                  ...prev,
                  free_luggage_kg: '',
               }));
            } else {
               setNewLuggageData((prev) => ({
                  ...prev,
                  free_luggage_kg: value,
               }));
            }
            break;

         case 'paid_max_luggage_kg':
            if (value > limit) {
               toast.error(`You can't take more than ${limit}`);
               setNewLuggageData((prev) => ({
                  ...prev,
                  paid_max_luggage_kg: limit,
               }));
               return;
            } else if (value < 0) {
               setNewLuggageData((prev) => ({
                  ...prev,
                  paid_max_luggage_kg: '',
               }));
            } else {
               setNewLuggageData((prev) => ({
                  ...prev,
                  paid_max_luggage_kg: value,
               }));
            }
            break;

         default:
            break;
      }
   };

   return (
      <>
         <InputArea>
            {/* <Children>
               <InputHeader htmlFor="children">
                  {
                     languageData?.booking_page_children_seat[
                        webSettingData?.language
                     ]
                  }
               </InputHeader>
               <SingleInput
                  id="children"
                  type="number"
                  inputProps={{ min: 0, max: bookingData?.cseat }}
                  min={0}
                  max={bookingData?.cseat}
                  value={childrenSelectSeat}
                  onChange={handleChildren}
                  placeholder="0"
               />
            </Children> */}
            {/* end children */}
            <Adult>
               <InputHeader htmlFor="adult">
                  {
                     languageData?.booking_page_adul_seat[
                        webSettingData?.language
                     ]
                  }
               </InputHeader>
               <SingleInput
                  id="adult"
                  type="number"
                  value={adultSelectSeat}
                  onChange={handleAdult}
                  placeholder="0"
               />
            </Adult>
            {/* end Adult */}
            {/* <Special>
               <InputHeader htmlFor="special">
                  {
                     languageData?.booking_page_special_seat[
                        webSettingData?.language
                     ]
                  }
               </InputHeader>
               <SingleInput
                  id="special"
                  type="number"
                  inputProps={{ min: 0, max: bookingData?.spseat }}
                  min={0}
                  max={bookingData?.spseat}
                  value={specialSelectSeat}
                  onChange={handleSpecial}
                  placeholder="0"
               />
            </Special> */}
            {webSettingData?.luggage_service && (
               <>
                  <FreeLuggage>
                     <InputHeader htmlFor="free_luggage_pcs">
                        {/* {
                     languageData?.booking_page_free_luggage_pcs[
                        webSettingData?.language
                     ]
                  } */}
                        Free Luggage ({luggageSettings?.free_luggage_pcs} Pcs)
                     </InputHeader>
                     <SingleInput
                        id="free_luggage_pcs"
                        type="number"
                        value={newLuggageData?.free_luggage_pcs}
                        onChange={(e) =>
                           handleLuggage(e, luggageSettings?.free_luggage_pcs)
                        }
                        placeholder="0"
                     />
                  </FreeLuggage>

                  <FreeLuggage>
                     <InputHeader htmlFor="free_luggage_kg">
                        {/* {
                     languageData?.booking_page_free_luggage_pcs[
                        webSettingData?.language
                     ]
                  } */}
                        Free Luggage ({luggageSettings?.free_luggage_kg} Kg)
                     </InputHeader>
                     <SingleInput
                        id="free_luggage_kg"
                        type="number"
                        value={newLuggageData?.free_luggage_kg}
                        onChange={(e) =>
                           handleLuggage(e, luggageSettings?.free_luggage_kg)
                        }
                        placeholder="0"
                     />
                  </FreeLuggage>

                  <PaidLuggage>
                     <InputHeader htmlFor="paid_max_luggage_pcs">
                        {/* {
                     languageData?.booking_page_free_luggage_pcs[
                        webSettingData?.language
                     ]
                  } */}
                        Paid Luggage (Max{' '}
                        {luggageSettings?.paid_max_luggage_pcs} Pcs) | Price per
                        pcs ({luggageSettings?.price_pcs})
                     </InputHeader>
                     <SingleInput
                        id="paid_max_luggage_pcs"
                        type="number"
                        value={newLuggageData?.paid_max_luggage_pcs}
                        onChange={(e) =>
                           handleLuggage(
                              e,
                              luggageSettings?.paid_max_luggage_pcs
                           )
                        }
                        placeholder="0"
                     />
                  </PaidLuggage>

                  <PaidLuggage>
                     <InputHeader htmlFor="paid_max_luggage_kg">
                        {/* {
                     languageData?.booking_page_free_luggage_pcs[
                        webSettingData?.language
                     ]
                  } */}
                        Paid Luggage (Max {luggageSettings?.paid_max_luggage_kg}{' '}
                        Kg) | Price per kg ({luggageSettings?.price_kg})
                     </InputHeader>
                     <SingleInput
                        id="paid_max_luggage_kg"
                        type="number"
                        value={newLuggageData?.paid_max_luggage_kg}
                        onChange={(e) =>
                           handleLuggage(
                              e,
                              luggageSettings?.paid_max_luggage_kg
                           )
                        }
                        placeholder="0"
                     />
                  </PaidLuggage>

                  {/* end Spcial */}
               </>
            )}
            {webSettingData?.luggage_service && (
               <CheckBoxUl>
                  <CommonNavItem key={'item.id'}>
                     <Input
                        type="checkbox"
                        className="form-check-input"
                        id={'item.id'}
                        name="vehicle1"
                        value={'item.type'}
                        onChange={() => setShowSpecialLuggage((prev) => !prev)}
                        checked={showSpecialLuggage}
                     />
                     <CommonLabel htmlFor={'item.id'}>
                        Special Luggage
                     </CommonLabel>
                  </CommonNavItem>
               </CheckBoxUl>
            )}
            {showSpecialLuggage && (
               <SpecialLuggageWrap>
                  <InputHeader htmlFor="free_luggage_pcs">
                     {/* {
                     languageData?.booking_page_free_luggage_pcs[
                        webSettingData?.language
                     ]
                  } */}
                     Item Name
                  </InputHeader>
                  <SingleInput
                     id="free_luggage_pcs"
                     type="text"
                     value={specialLuggageName}
                     onChange={(e) => setSpecialLuggageName(e.target.value)}
                     placeholder="Special Item Name"
                  />
               </SpecialLuggageWrap>
            )}
         </InputArea>
         {/* end input area */}
      </>
   );
};

export default SeatCount;
